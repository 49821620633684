import React, {useState} from "react";
import Popup from 'reactjs-popup';
import { Button} from 'react-bootstrap';

const  Features = props => {
    return (
      <div id="projects" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Projects</h2>
          </div>
          <div id="row">
              {props.data
                ? props.data.map((d, i) => (
                    <div  key={`${d.title}-${i}`} className="col-md-3 col-sm-6 team"> 
                      <div className="thumbnail">
                        {" "}
                        <img src={d.img} alt="..." className="team-img" />
                        <div className="caption">
                        <h4>{d.title}</h4>
                        <p>{d.text}</p>
                        </div>
                      </div>
                    </div>
                  ))
              : "loading"}
          </div>
        </div>
      </div>
    );
}

export default Features;
