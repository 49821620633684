import React, { useState } from "react";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      fontSize:20
    },
  },
}));

export default function Contact(props) {
  const classes = useStyles();

const [formData, setFormData] = useState({})
const [message, setMessage]  = useState(false)
const [open, setOpen] = useState(false);
  

const handleInput = (e) => {
  const copyFormData = { ...formData }
  copyFormData[e.target.name] = e.target.value
  setFormData(copyFormData)
}

const sendData = async (e) => {
  e.preventDefault()
  e.target.reset()
  const { name, email, message } = formData
  try {
    const response = await fetch(
      "https://v1.nocodeapi.com/rey/google_sheets/gQYcKDMVmFHjDORE?tabId=Sheet1",
      {
        method: "post",
        body: JSON.stringify([[name, email, message]]),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const json = await response.json()
    console.log("Success:", JSON.stringify(json))
    setMessage("Submitted Succesfully!")
  } catch (error) {
    console.error("Error:", error)
    setMessage("Error")
  }
}
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we
                  will get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" id="contactForm" onSubmit={sendData}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        onChange={handleInput}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        onChange={handleInput}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    onChange={handleInput}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>

                <Collapse in={open}>
                  <Alert
                  fontSize="large"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Submitted Succefully
                  </Alert>
                </Collapse>

                <button 
                  type="submit"
                  value="Send"
               
                 variant="outlined"
                 onClick={() => {
                   setOpen(true);
                 }}
                 className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2021 LEMMANUEL EQUIPMENT AND CONSTRUCTION CORP. 
          </p>
        </div>
      </div>
    </div>
  );
}

