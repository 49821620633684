import React, { Component } from "react";
import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: 'img/portfolio/image6.jpg',
    thumbnail: 'img/portfolio/image6.jpg',
  },
  {
    original: 'img/portfolio/image2.jpg',
    thumbnail: 'img/portfolio/image2.jpg',
  },
  {
    original: 'img/portfolio/image3.jpg',
    thumbnail: 'img/portfolio/image3.jpg',
  },
  {
    original: 'img/portfolio/image4.jpg',
    thumbnail: 'img/portfolio/image4.jpg',
  },
  {
    original: 'img/portfolio/image5.jpg',
    thumbnail: 'img/portfolio/image5.jpg',
  },
  {
    original: 'img/portfolio/image6.jpg',
    thumbnail: 'img/portfolio/image6.jpg',
  },
  {
    original: 'img/portfolio/image8.jpg',
    thumbnail: 'img/portfolio/image8.jpg',
  },
  {
    original: 'img/portfolio/image9.jpg',
    thumbnail: 'img/portfolio/image9.jpg',
  },
  {
    original: 'img/portfolio/image10.jpg',
    thumbnail: 'img/portfolio/image10.jpg',
  },
  {
    original: 'img/portfolio/image16.jpg',
    thumbnail: 'img/portfolio/image16.jpg',
  },
];

export class Gallery extends Component {
  render() {
    return (
      <div id="equipments" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Equipments</h2>
          </div>
          <ImageGallery items={images} />
        </div>
      </div>
    );
  }
}

export default Gallery;
