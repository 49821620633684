import React, { Component } from "react";

export class Navigation extends Component {
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top ">
        <div className="">
          <div className="navbar-header">
            <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                {" "}
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand " href="#page-top">
                LEMMANUEL EQUIPMENT
              & CONSTRUCTION CORP.
            </a>{" "}
          </div>
          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                  <a href="#about" className="page-scroll">
                    About
                  </a>
              </li>

              <li>
                <a href="#projects" className="page-scroll">
                  Projects
                </a>
              </li>

              <li>
                <a href="#equipments" className="page-scroll">
                  Equipments
                </a>
              </li>
            
              <li>
                <a href="#contact" className="page-scroll">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
